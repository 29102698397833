import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

import { Button, IconButton, Tooltip } from '@material-ui/core';
import * as I from '@material-ui/icons';

import { ducks, useApi } from '@arborian/narrf';

import { url_for } from './Router';

function LogoutButton() {
    const api = useApi();
    const history = useHistory();

    const logout = useCallback(async () => {
        await api.logout();
        history.push(url_for('home'));
    }, [api, history]);

    return (
        <Tooltip title="Log out">
            <IconButton onClick={logout} color="inherit">
                <I.PowerSettingsNew />
            </IconButton>
        </Tooltip>
    );
}

function LogoutAllButton() {
    const api = useApi();
    const history = useHistory();

    const logoutAll = useCallback(async () => {
        await api.logout({ revoke_all: true });
        history.push(url_for('home'));
    }, [api, history]);

    return (
        <Tooltip title="Log out of all sessions">
            <IconButton onClick={logoutAll} color="inherit">
                <I.SettingsPower />
            </IconButton>
        </Tooltip>
    );
}

function AuthInfoAuthorized({ userinfo }) {
    return (
        <span>
            Logged in as{' '}
            <Button color="inherit" component={Link} to={url_for('profile')}>
                {userinfo.name}
            </Button>
            <LogoutButton />
            <LogoutAllButton />
        </span>
    );
}

function AuthInfoAnonymous() {
    return (
        <>
            <Button color="inherit" component={Link} to={url_for('login')}>
                Login
            </Button>
        </>
    );
}

export default function AuthInfo() {
    const userinfo = useSelector(ducks.auth.selectUserinfo);

    return userinfo ? <AuthInfoAuthorized userinfo={userinfo} /> : <AuthInfoAnonymous />;
}
