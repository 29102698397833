import { FormControlLabel, FormHelperText, Switch } from '@material-ui/core';
import { useFormikContext } from 'formik';

export function FormikSwitch({ name, label, readonly = false }) {
    const formik = useFormikContext();

    return (
        <>
            <FormControlLabel
                label={label}
                checked={formik.values[name]}
                control={<Switch name={name} value={formik.values[name]} onChange={formik.handleChange} disabled={readonly} />}
            />
            {formik.touched[name] && formik.errors[name] && <FormHelperText>{formik.errors[name]}</FormHelperText>}
        </>
    );
}
