import React from 'react';
import * as R from 'react-router-dom';

import { reverse } from 'named-urls';

import { ApiRoute } from '@arborian/narrf';

import HomePage from '../pages/HomePage';
import LoginPage from '../pages/LoginPage';
import CallbackPage from '../pages/CallbackPage';
import AuthorizedPage from '../pages/AuthorizedPage';
import ConfigurationListPage from '../pages/ConfigurationListPage';
import BackfillPage from '../pages/BackfillPage';

export const routes = {
    home: { path: '/' },
    login: { path: '/login' },
    profile: { path: '/profile' },
    callback: { path: '/callback' },
    configurations: { path: '/configurations' },
    backfill: { path: '/backfill' },
    authorized: { path: '/authorized' },
};

export const url_for = (route, params) => {
    try {
        let { path } = routes[route];
        return reverse(path, params);
    } catch (e) {
        console.error('Error looking up route', route, params);
        throw e;
    }
};

export default function Router() {
    return (
        <R.BrowserRouter>
            <R.Switch>
                <ApiRoute exact path={routes.home.path} component={HomePage} />
                <ApiRoute exact path={routes.login.path} component={LoginPage} />
                <ApiRoute exact path={routes.callback.path} component={CallbackPage} />
                <ApiRoute exact authorized path={routes.configurations.path} component={ConfigurationListPage} />
                <ApiRoute exact authorized path={routes.backfill.path} component={BackfillPage} />
                <ApiRoute exact authorized path={routes.authorized.path} component={AuthorizedPage} />
            </R.Switch>
        </R.BrowserRouter>
    );
}
