import { components } from '@arborian/narrf';
import { useFormikContext } from 'formik';
import get from 'lodash/fp/get';
import React, { useEffect, useRef, useState } from 'react';

export function FormikDataTable({ name, label, children, editable = true }) {
    const formik = useFormikContext();

    const tableRef = useRef();

    const [data, setData] = useState(() => {
        return (get(name, formik.values) ?? []).map((item, index) => ({
            id: index,
            ...item,
        }));
    });

    useEffect(() => {
        formik.setFieldValue(name, data.map(({ id, ...item }) => item));

        // refresh after data applied
        setTimeout(() => {
            tableRef.current.fetch();
        }, 0);
    }, [data]);

    const fetchConfigurationList = () => {
        return {
            rows: data,
            count: data.length,
        };
    };

    const onRowAdd = newItem => {
        setData(prev => [
            ...prev,
            {
                id: data.length,
                ...newItem.data,
            },
        ]);
    };

    const onRowUpdate = (newItem, oldItem, ...props) => {
        setData(prev => [
            ...prev.map(item => {
                if (item.id === newItem.id) {
                    return newItem.data;
                }

                return item;
            }),
        ]);
    };

    const onRowDelete = item => {
        setData(prev => [
            ...prev.filter(i => {
                return i.id !== item.id;
            }),
        ]);
    };

    return (
        <components.DataTable
            tableRef={tableRef}
            title={label}
            data={fetchConfigurationList}
            editable={editable ? { onRowAdd, onRowUpdate, onRowDelete } : { onRowAdd: null, onRowUpdate: null, onRowDelete: null }}
        >
            {children}
        </components.DataTable>
    );
}
