import { InputLabel, MenuItem, Select } from '@material-ui/core';
import { useFormikContext } from 'formik';
import get from 'lodash/fp/get';

export function FormikDropDownField({ name, label, options }) {
    const formik = useFormikContext();
    const inputLabelId = `dropdown-${Date.now()}`;

    return (
        <>
            <InputLabel id={inputLabelId}>{label}</InputLabel>
            <Select
                labelId={inputLabelId}
                name={name}
                value={get(name, formik.values)}
                onChange={formik.handleChange}
                error={get(name, formik.touched) && Boolean(get(name, formik.errors))}
            >
                <MenuItem value="" key="0">
                    <em>None</em>
                </MenuItem>
                {options.map(option => (
                    <MenuItem value={option.value} key={option.value}>
                        {option.title}
                    </MenuItem>
                ))}
            </Select>
        </>
    );
}
