import { ClientConfigForm } from './ClientConfigForm';
import { components, useApi } from '@arborian/narrf';
import { generateConfigurationDefaultValues } from './generateConfigurationDefaultValues';
import { parseUnexpectedEntityResponseToErrorsMap } from '../../utils/unexpectedEntityResponse';
// import { fakeConfiguration } from '../../faker/configuration';

export function ConfigurationCreateForm({ dialogRef }) {
    const api = useApi();

    const initialValues = generateConfigurationDefaultValues();
    // use for test, allow fast generating new configuration
    // const initialValues = fakeConfiguration();

    const onSubmit = async (values, { setErrors }) => {
        const { team_config, ...attributes } = values;

        const url = api.directory.data.links['client_config.ClientConfigCollection'];

        try {
            const configurationCreateResponse = await api.fetchJson(url, {
                method: 'POST',
                json: {
                    data: {
                        type: 'ClientConfig',
                        attributes,
                    },
                },
            });

            dialogRef.current.handleSubmit();
        } catch (err) {
            const errors = await parseUnexpectedEntityResponseToErrorsMap(err);
            setErrors(errors);
        }
    };

    return (
        <components.FormDialog
            dialogRef={dialogRef}
            dialogProps={{ fullWidth: true, maxWidth: 'lg' }}
            initialValues={initialValues}
            onSubmit={onSubmit}
        >
            <ClientConfigForm action="Create" />
        </components.FormDialog>
    );
}
