import { components } from '@arborian/narrf';
import { useFormikContext } from 'formik';
import get from 'lodash/fp/get';
import React, { useEffect, useRef, useState } from 'react';

export function FormikMap({ name, label }) {
    const formik = useFormikContext();

    const tableRef = useRef();

    const [map, setMap] = useState(() => {
        return get(name, formik.values) ?? {};
    });

    const data = Object.entries(map).map(([key, value]) => ({
        id: key,
        key,
        value,
    }));

    useEffect(() => {
        formik.setFieldValue(name, map);

        // refresh after data applied
        setTimeout(() => {
            tableRef.current.fetch();
        }, 0);
    }, [map]);

    const fetchConfigurationList = () => {
        return {
            rows: data,
            count: data.length,
        };
    };

    const onRowAdd = ({ data: { key, value } }) => {
        setMap(prev => ({
            ...prev,
            [key]: value,
        }));
    };

    const onRowUpdate = ({ data: { key, value } }, { data: { key: oldKey } }) => {
        setMap(prev =>
            Object.entries(prev)
                .filter(([key]) => key !== oldKey)
                .reduce(
                    (acc, [key, value]) => {
                        acc[key] = value;
                        return acc;
                    },
                    {
                        [key]: value,
                    },
                ),
        );
    };

    const onRowDelete = item => {
        setMap(prev =>
            Object.entries(prev)
                .filter(([key]) => key !== item.id)
                .reduce((acc, [key, value]) => {
                    acc[key] = value;
                    return acc;
                }, {}),
        );
    };

    return (
        <components.DataTable
            tableRef={tableRef}
            title={label}
            data={fetchConfigurationList}
            editable={{ onRowAdd, onRowUpdate, onRowDelete }}
        >
            <components.Column title="Key" field="key" />
            <components.Column title="Value" field="value" />
        </components.DataTable>
    );
}
