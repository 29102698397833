import set from 'lodash/fp/set'

export async function parseUnexpectedEntityResponseToErrorsMap(err) {
    const response = await err.json();

    let fields = {};

    if (response.code === 422) {
        const { errors: { json: { errors = [] } } } = response;
        for (const { detail, source: { pointer } } of errors) {
            // skip 3 slashes: /data/attributes/
            const path = pointer.split('/').slice(3).join('.');
            fields = set(path, detail, fields);
        }
    }

    return fields;
}