import { ClientConfigForm } from './ClientConfigForm';
import { components, useApi } from '@arborian/narrf';
import { useEffect, useRef, useState} from 'react';
import { parseUnexpectedEntityResponseToErrorsMap } from '../../utils/unexpectedEntityResponse';

export function ConfigurationUpdateForm({ dialogRef }) {
    const api = useApi();

    const [item, setItem] = useState(null);
    const updateDialogRef = useRef();

    useEffect(() => {
        if (item) {
            updateDialogRef.current.open();
        }
    }, [item]);

    const open = async configuration => {
        const url = api.directory.data.links['client_config.ClientConfig'].replace('{client_id}', configuration.id);
        const data = await api.fetchJson(url);
        setItem(data);
    };

    dialogRef.current = { open };

    if (!item) {
        return null;
    }

    const onSubmit = async (values, { setErrors }) => {
        const { team_config, ...attributes } = values;

        const url = api.directory.data.links['client_config.ClientConfig'].replace('{client_id}', item.data.id);

        try {
            const configurationUpdateResponse = await api.fetchJson(url, {
                method: 'PUT',
                json: {
                    data: {
                        type: 'ClientConfig',
                        attributes,
                    },
                },
            });

            updateDialogRef.current.handleSubmit();
        } catch (err) {
            const errors = await parseUnexpectedEntityResponseToErrorsMap(err);
            setErrors(errors);
        }
    };

    return (
        <components.FormDialog
            dialogRef={updateDialogRef}
            dialogProps={{ fullWidth: true, maxWidth: 'lg' }}
            initialValues={{ ...item.data.attributes, id: item.data.id }}
            onSubmit={onSubmit}
        >
            <ClientConfigForm action="Update" />
        </components.FormDialog>
    );
}
