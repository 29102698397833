import React, {useState, useEffect} from "react";
import {
    Button,
    Card,
    CardActions,
    CardContent,
    FormControl,
    FormGroup,
    makeStyles,
    Paper
} from '@material-ui/core';
import { FormikSwitch } from '../inputs/FormikSwitch';
import {useFormikContext} from 'formik';
import { FormikDropDownField } from "../inputs/FormikDropDownField";
import {useApi} from "@arborian/narrf";
import {FormikTextField} from "../inputs/FormikTextField";

const useStyles = makeStyles(theme => ({
    submitButton: {
        marginTop: theme.spacing(3),
    },
}));

export function BackfillForm({ action }) {
    const classes = useStyles();

    const formik = useFormikContext();
    const api = useApi();

    const [teamIdsLoaded, setTeamIdsLoaded] = useState(false);
    const [teamIds, setTeamIds] = useState([]);

    const loadTeamIds = async () => {
        const url = api.directory.data.links['backfill.BackfillUpload'];
        const data = await api.fetchJson(url);
        let ids = data.map((elem) => {return {title: elem.name + " ----  team_id:" + elem.id, value: elem.id}});
        setTeamIds(ids);
        setTeamIdsLoaded(true);
    }

    useEffect(() => {
        if(!teamIdsLoaded){
            loadTeamIds();
        }
    }, [teamIdsLoaded, loadTeamIds]);


    return (

            <Paper>
                <form onSubmit={formik.handleSubmit}>
                    <Card variant="outlined">
                        <CardContent>
                            <FormGroup>
                                <FormControl>
                                    <FormikTextField name="protocol_nos"
                                                     label="Protocol Numbers"
                                                     multiline={true}
                                                     minRows="10"
                                    >
                                    </FormikTextField>
                                </FormControl>
                                <FormControl>
                                    {teamIdsLoaded ? (
                                        <FormikDropDownField label="Team Id" name="team_id" options={teamIds} />
                                    ) : (
                                        <div>Loading...</div>
                                    )}
                                </FormControl>
                                <FormControl>
                                    <FormikSwitch label="Send notifications" name="notify" />
                                </FormControl>
                            </FormGroup>
                        </CardContent>
                        <CardActions>
                            <Button className={classes.submitButton} color="primary" variant="contained" type="submit">
                                Send
                            </Button>
                        </CardActions>
                    </Card>
                </form>
            </Paper>
    );
}