import { TextField } from '@material-ui/core';
import { useFormikContext } from 'formik';
import get from 'lodash/fp/get';

export function FormikTextField({ name, label, type = 'text', multiline = false, minRows = 1, readonly = false }) {
    const formik = useFormikContext();

    return (
        <TextField
            type={type}
            label={label}
            name={name}
            value={get(name, formik.values)}
            onChange={formik.handleChange}
            error={get(name, formik.touched) && Boolean(get(name, formik.errors))}
            helperText={get(name, formik.touched) && get(name, formik.errors)}
            multiline={multiline}
            minRows={minRows}
            disabled={readonly}
        />
    );
}
