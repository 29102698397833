import React, { useRef } from 'react';

import BasePage from './BasePage';

import { ConfigurationDataTable } from '../components/configuration/ConfigurationDataTable';
import { ConfigurationCreateForm } from '../components/configuration/ConfigurationCreateForm';
import { ConfigurationUpdateForm } from '../components/configuration/ConfigurationUpdateForm';

export default function ConfigurationListPage() {
    const createFormDialogRef = useRef();
    const updateFormDialogRef = useRef();
    const tableRef = useRef();

    const onCreateConfiguration = () => {
        createFormDialogRef.current.open();
        tableRef.current.fetch();
    };

    const onUpdateConfiguration = item => {
        updateFormDialogRef.current.open(item);
        tableRef.current.fetch();
    };

    return (
        <BasePage subtitle="Configuration List">
            <h2>Configuration List</h2>
            <ConfigurationCreateForm dialogRef={createFormDialogRef} />
            <ConfigurationUpdateForm dialogRef={updateFormDialogRef} />
            <ConfigurationDataTable
                tableRef={tableRef}
                onCreateConfiguration={onCreateConfiguration}
                onUpdateConfiguration={onUpdateConfiguration}
            />
        </BasePage>
    );
}
