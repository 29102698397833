import React, { useState } from "react";
import { Button, Card, CardActions, CardContent, makeStyles, Paper } from '@material-ui/core';
import { components, useApi } from "@arborian/narrf";
import { FormikTextField } from '../inputs/FormikTextField';
import { FormikSwitch } from '../inputs/FormikSwitch';
import { useFormikContext } from 'formik';
import { FormFieldGroup } from '../form/FormFieldGroup';
import { FormikDataTable } from '../inputs/FormikDataTable';
import { FormikMap } from "../inputs/FormikMap";
import { FormikDropDownField } from "../inputs/FormikDropDownField";
import { FormikDateField } from "../inputs/FormikDateField";
import { ArrayRender } from "../datatable/renders";

const useStyles = makeStyles(theme => ({
    submitButton: {
        marginTop: theme.spacing(3),
    },
}));

export function ClientConfigForm({ action }) {
    const classes = useStyles();

    const formik = useFormikContext();

    const environmentOptions = [
        { title: 'UAT', value: 'uat' },
        { title: 'PROD', value: 'prod' },
    ];

    const binderCreationMethodOptions = [
        { title: 'Default', value: 'default' },
        { title: 'Annotations Non Binary', value: 'annotations_non_binary' },
    ];

    return (
        <Paper>
            <form onSubmit={formik.handleSubmit}>
                <Card>
                    <CardContent>
                        <FormFieldGroup label="Attributes" defaultExpanded={true}>
                            <FormikTextField label="ID" name="id" />
                            <FormikDropDownField label="Environment" name="environ" options={environmentOptions} />
                            <FormikSwitch label="Active" name="active" />
                            <FormikTextField label="Name" name="name" />
                            <FormikTextField label="Annotation Name" name="annotation_name" />
                            <FormikSwitch label="On Premise" name="onprem" />
                            <FormikTextField
                                label="Database Query Interval"
                                name="database_query_interval"
                                type="number"
                            />
                            <FormikTextField
                                label="Binder Creation Window"
                                name="binder_creation_window"
                                type="number"
                            />
                            <FormikDropDownField label="Binder Creation Method" name="binder_creation_method" options={binderCreationMethodOptions} />
                            <FormikSwitch label="Use Short Title" name="use_short_title" />
                        </FormFieldGroup>

                        <TeamConfigFormGroup label="Team Configuration" nested="team_config" />
                        <FlorenceConfigFormGroup label="Florence" nested="florence" />
                        <OnCoreFormGroup label="On Core" nested="oncore" />

                        <MappingFormGroup label="Role Mapping" nested="role_mapping" />
                        <MappingFormGroup label="Protocol Type Mapping" nested="protocol_type_mapping" />
                        <MappingFormGroup label="DataTable Mapping" nested="datatable4_mapping" />
                    </CardContent>
                    <CardActions>
                        <Button className={classes.submitButton} color="primary" variant="contained" type="submit">
                            {action}
                        </Button>
                    </CardActions>
                </Card>
            </form>
        </Paper>
    );
}

function TeamConfigFormGroup({ label, nested }) {
    const api = useApi();

    const formik = useFormikContext();

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const onToggle = async (expanded) => {
        if (!expanded) {
            return;
        }

        setLoading(true);

        const url = api.directory.data.links['team_config.TeamConfig'].replace('{team_id}', formik.values.id);

        try {
            const data = await api.fetchJson(url);
            formik.setFieldValue('team_config', data);
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    }

    return (
        <FormFieldGroup label={label} onToggle={onToggle}>
            {loading ? <div>Loading....</div> : null}
            {error ? <div>{error}</div> : null}
            {(!loading && !error) ? <>
                <FormikDateField label="Created At" name={`${nested}.createdAt`} readonly/>
                <FormikTextField label="Name" name={`${nested}.name`} readonly/>
                <FormikSwitch label="Has Global Binders" name={`${nested}.hasGlobalBinders`} readonly/>

                <StudiesFormGroup label="Studies" nested={`${nested}.studies.0`}/>
            </> : null}
        </FormFieldGroup>
    );
}

function FlorenceConfigFormGroup({ label, nested }) {
    return (
        <FormFieldGroup label={label}>
            <FormikTextField label="Base URL" name={`${nested}.base_url`} />
            <FormikTextField label="Username" name={`${nested}.user_name`} />
            <FormikTextField label="Private Key" name={`${nested}.private_key`} type="password" />
            <FormikTextField label="Root Container Path" name={`${nested}.root_container_path`} />
            <FormikSwitch label="Send Notifications" name={`${nested}.send_notifications`} />
            <FormikSwitch label="Create Global Binders" name={`${nested}.create_global_binders`} />
            <FormikTextField label="AWS Access Key" name={`${nested}.aws_access_key`} type="password" />
            <FormikTextField label="AWS Secret Key" name={`${nested}.aws_secret_key`} type="password" />
            <FormikTextField label="SQS Queue Url" name={`${nested}.sqs_queue_url`} />
            <StudyProfileFromGroup label="Study Profile" nested={`${nested}.study_profile`} />
        </FormFieldGroup>
    );
}

function StudyProfileFromGroup({ label, nested }) {
    return (
        <FormFieldGroup label={label}>
            <FormikTextField label="Condition or Disease Area" name={`${nested}.conditionOrDiseaseArea`} />
            <FormikTextField label="CRO Name" name={`${nested}.croName`} />
            <FormikTextField label="Device or Drug" name={`${nested}.deviceOrDrug`} />
            <FacilityFromGroup label="Facility" nested={`${nested}.facility`} />
            <SponsorFromGroup label="Sponsor" nested={`${nested}.sponsor`} />
            <StudySiteFromGroup label="Study Site" nested={`${nested}.studySite`} />
        </FormFieldGroup>
    );
}

function FacilityFromGroup({ label, nested }) {
    return (
        <FormFieldGroup label={label}>
            <FormikTextField label="Country Code" name={`${nested}.countryCode`} />
            <FormikTextField label="Organization Name" name={`${nested}.organizationName`} />
        </FormFieldGroup>
    );
}

function SponsorFromGroup({ label, nested }) {
    return (
        <FormFieldGroup label={label}>
            <FormikTextField label="External System Sponsor ID" name={`${nested}.externalSystemSponsorId`} />
            <FormikTextField label="Sponsor Name" name={`${nested}.sponsorName`} />
        </FormFieldGroup>
    );
}

function StudySiteFromGroup({ label, nested }) {
    return (
        <FormFieldGroup label={label}>
            <FormikTextField label="External System Site ID" name={`${nested}.externalSystemSiteId`} />
            <FormikTextField label="Lead Coordinator Name" name={`${nested}.leadCoordinatorName`} />
            <FormikTextField label="Principal Investigator Name" name={`${nested}.principalInvestigatorName`} />
            <FormikTextField label="Site ID" name={`${nested}.siteId`} />
            <FormikTextField label="Site Name" name={`${nested}.siteName`} />
        </FormFieldGroup>
    );
}

function OnCoreFormGroup({ label, nested }) {
    return (
        <FormFieldGroup label={label}>
            <OracleConfigurationFormGroup label="DB" nested={`${nested}.db`} />
            <OncoreApiCredentialsFormGroup label="API" nested={`${nested}.api`} />
            <FormikTextField label="Protocol Filter" name={`${nested}.protocol_filter`} />
            <FormikTextField label="Polling Interval Seconds" name={`${nested}.polling_interval_s`} type="number" />
        </FormFieldGroup>
    );
}

function OracleConfigurationFormGroup({ label, nested }){
    return (
        <FormFieldGroup label={label}>
            <FormikTextField label="DB Uri" name={`${nested}.dburi`} />
            <FormikTextField label="Schema Name" name={`${nested}.schema_name`} />
            <FormikTextField label="Username" name={`${nested}.username`} />
            <FormikTextField label="Password" name={`${nested}.password`} type='password' />
        </FormFieldGroup>
    )
}

function OncoreApiCredentialsFormGroup({ label, nested }){
    return (
        <FormFieldGroup label={label}>
            <FormikTextField label="Base Url" name={`${nested}.base_url`} />
            <FormikTextField label="Client ID" name={`${nested}.client_id`} />
            <FormikTextField label="Client Secret" name={`${nested}.client_secret`} type='password' />
            <SessionConfigFormGroup label="Session Config" nested={`${nested}.session_config`} />
        </FormFieldGroup>
    )
}

function SessionConfigFormGroup({ label, nested }) {
    return <FormFieldGroup label={label}>
        <FormikMap label={label} name={nested} />
    </FormFieldGroup>;
}

function MappingFormGroup({ label, nested }) {
    return (
        <FormFieldGroup label={label}>
            <FormikDataTable label={label} name={nested}>
                <components.Column title="OnCore" field="oncore" />
                <components.Column title="Florence" field="florence" />
            </FormikDataTable>
        </FormFieldGroup>
    );
}

function StudiesFormGroup({ label, nested }) {
    return (
        <FormFieldGroup label={label}>
            <FormikTextField label="ID" name={`${nested}.id`} readonly />
            <FormikTextField label="Naming Convention" name={`${nested}.namingConvention`} readonly />
            <FormikTextField label="Type" name={`${nested}.type`} readonly />
            <StudiesPermissionsFormGroup label="Permissions" nested={`${nested}.permissions`} />
            <StudiesRolesFormGroup label="Roles" nested={`${nested}.roles`} />
        </FormFieldGroup>
    )
}

function StudiesPermissionsFormGroup({ label, nested }) {
    return (
        <FormFieldGroup label={label}>
            <FormikDataTable label={label} name={nested} editable={false}>
                <components.Column title="Role Number" field="roleNumber" />
                <components.Column title="Object Type" field="objectType" />
                <components.Column title="Path" field="path" />
                <components.Column title="Privileges" field="privileges" render={ArrayRender()} />
            </FormikDataTable>
        </FormFieldGroup>
    );
}

function StudiesRolesFormGroup({ label, nested }) {
    const externalRoleRender = item => `${item.id} - ${item.name}`;

    return (
        <FormFieldGroup label={label}>
            <FormikDataTable label={label} name={nested} editable={false}>
                <components.Column title="Number" field="number" />
                <components.Column title="Name" field="name" />
                <components.Column title="Is Global" field="isGlobal" type='boolean' editable={false} />
                <components.Column title="External Roles" field="externalRoles" render={ArrayRender(externalRoleRender)} />
            </FormikDataTable>
        </FormFieldGroup>
    );
}
