import { Accordion, AccordionDetails, AccordionSummary, FormGroup, makeStyles, Typography } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
    root: {
        marginBottom: theme.spacing(1),
        gap: theme.spacing(1),
    },
    details: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(1),
        width: '100%',
    },
}));

export function FormFieldGroup({ label, children, defaultExpanded = false, onToggle = (expanded) => {} }) {
    const classes = useStyles();

    return (
        <FormGroup className={classes.root}>
            <Accordion defaultExpanded={defaultExpanded} onChange={(_, expanded) => onToggle(expanded)}>
                <AccordionSummary expandIcon={<ExpandMore />}>
                    <Typography variant="h5">{label}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <div className={classes.details}>
                        {children}
                    </div>
                </AccordionDetails>
            </Accordion>
        </FormGroup>
    );
}
