import React from 'react';
import { Link } from 'react-router-dom';

import BasePage from './BasePage';
import { routes } from '../components/Router';

export default function HomePage() {
    return (
        <BasePage subtitle="Home">
            <h2>Home Page</h2>
            <Link style={{ display: 'block' }} to={routes.configurations.path}>Configuration List</Link>
            <Link style={{ display: 'block' }} to={routes.backfill.path}>Backfill</Link>
        </BasePage>
    );
}
