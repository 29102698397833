import { TextField } from "@material-ui/core";
import { useFormikContext } from 'formik';
import get from 'lodash/fp/get';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';

export function FormikDateField({ name, label, readonly = false }) {
    const formik = useFormikContext();

    let value = get(name, formik.values);
    if (value) {
        value = format(parseISO(get(name, formik.values)), `yyyy-MM-dd'T'HH:ii:ss`);
    }

    return (
        <TextField
            type='datetime-local'
            label={label}
            name={name}
            value={value}
            onChange={formik.handleChange}
            error={get(name, formik.touched) && Boolean(get(name, formik.errors))}
            helperText={get(name, formik.touched) && get(name, formik.errors)}
            disabled={readonly}
        />
    );
}
