import React from 'react';
import { useSelector } from 'react-redux';
import fp from 'lodash/fp';
import * as R from 'react-router-dom';

import { Drawer, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import * as I from '@material-ui/icons';

import { url_for } from './Router';
import { useApi, ducks } from '@arborian/narrf';

function AuthorizedEntries() {
    return <></>;
}

function AdminEntries() {
    return <></>;
}

export default function NavDrawer({ open, onClose }) {
    const api = useApi();
    const userinfo = useSelector(ducks.auth.selectUserinfo);

    const isAdmin = fp.flow([fp.get('scopes'), fp.indexOf('__admin__')])(userinfo) !== -1;

    return (
        <Drawer open={open} onClose={onClose}>
            <List>
                <ListItem button component={R.Link} to={url_for('home')}>
                    <ListItemIcon>
                        <I.Home />
                    </ListItemIcon>
                    <ListItemText primary="Home" />
                </ListItem>
                <ListItem button component={R.Link} to={url_for('configurations')}>
                    <ListItemIcon>
                        <I.Build />
                    </ListItemIcon>
                    <ListItemText primary="Configuration List" />
                </ListItem>
                <ListItem button component={R.Link} to={url_for('backfill')}>
                    <ListItemIcon>
                        <I.FileCopy />
                    </ListItemIcon>
                    <ListItemText primary="Backfill" />
                </ListItem>
                {api.isAuthorized() && <AuthorizedEntries />}
                {isAdmin && <AdminEntries />}
            </List>
        </Drawer>
    );
}
