import React from 'react';
import { useSelector } from 'react-redux';

import { ducks } from '@arborian/narrf';

import BasePage from './BasePage';

export default function AuthorizedPage() {
    const userinfo = useSelector(ducks.auth.selectUserinfo);

    return (
        <BasePage subtitle="Authorized">
            <pre>{JSON.stringify(userinfo, null, 2)}</pre>
        </BasePage>
    );
}
