import React, { useState } from 'react';

import { makeStyles } from '@material-ui/core';

import AppBar from '../components/AppBar';
import NavDrawer from '../components/NavDrawer';

const useStyles = makeStyles(theme => ({
    root: {
        '& h1': {
            ...theme.typography.h1,
            margin: 0,
        },
        '& h2': theme.typography.h2,
        '& h3': theme.typography.h3,
        '& h4': {
            ...theme.typography.h4,
            margin: 0,
        },
        '& h5': theme.typography.h5,
        '& h6': theme.typography.h6,
        '& p': theme.typography.body1,
        'height': '100vh',
        '& a.green-link': {
            color: '#459e76',
        },
    },
    footer: {
        position: 'fixed',
        bottom: 0,
        width: '100%',
        textAlign: 'center',
        backgroundColor: '#F6F7FF',
        height: 56,
    },
    contentContainer: {
        padding: 16,
        marginBottom: 56,
    },
    contentArea: {
        maxWidth: 1280,
        marginLeft: 'auto',
        marginRight: 'auto',
    },
}));

export default function BasePage({ title = 'Florence Configurator', subtitle, children }) {
    const classes = useStyles();
    const [drawerOpen, setDrawerOpen] = useState(false);

    return (
        <div className={classes.root}>
            <NavDrawer open={drawerOpen} onClose={() => setDrawerOpen(false)} />
            <AppBar title={title} subtitle={subtitle} onToggleDrawer={() => setDrawerOpen(!drawerOpen)} />
            <div className={classes.contentContainer}>
                <div className={classes.contentArea}>{children}</div>
            </div>
            <footer className={classes.footer}>
                <p>&copy; 2020 Arborian Consulting, LLC. All Rights Reserved.</p>
            </footer>
        </div>
    );
}
