import { components, useApi } from '@arborian/narrf';
import { Add, Edit, Refresh } from '@material-ui/icons';
import React from 'react';

export function ConfigurationDataTable({ onCreateConfiguration, onUpdateConfiguration, tableRef }) {
    const api = useApi();

    const refresh = () => {
        tableRef.current.fetch();
    };

    const fetchConfigurationList = async options => {
        const data = await api.fetchDataTable(
            api.directory.data.links['client_config.ClientConfigCollection'],
            options,
        );

        return data;
    };

    const createConfiguration = async () => {
        await onCreateConfiguration();
    };

    const updateConfiguration = async (event, item) => {
        await onUpdateConfiguration(item.data);
    };

    return (
        <components.DataTable title="Configurations" tableRef={tableRef} data={fetchConfigurationList}>
            <components.Column title="ID" field="id" />
            <components.Column title="Env" field="attributes.environ" />
            <components.Column title="Active" field="attributes.active" />
            <components.Column title="Name" field="attributes.name" />
            <components.Column title="Annotation Name" field="attributes.annotation_name" />
            <components.Column title="On Premise" field="attributes.onprem" />
            <components.Column title="Database Query Interval" field="attributes.database_query_interval" />
            <components.Column title="Base Url" field="attributes.florence.base_url" />
            <components.Column title="Username" field="attributes.florence.user_name" />
            <components.Column title="DB Uri" field="attributes.oncore.db.dburi" />
            <components.Action icon={<Add />} tooltip="Add New" onClick={createConfiguration} free />
            <components.Action icon={<Refresh />} tooltip="Refresh" onClick={refresh} free />
            <components.Action icon={<Edit />} tooltip="Edit" onClick={updateConfiguration} />
        </components.DataTable>
    );
}
