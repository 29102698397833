import fp from 'lodash/fp';

export function ArrayRender(itemRender = (item) => item) {
    return function (row) {
        let value = this.field ? fp.get(this.field, row.data) : null;
        if (Array.isArray(value)) {
            return (
                <ol>
                    {value.map(v => <li>{itemRender(v)}</li>)}
                </ol>
            )
        }
        return null;
    }
}
