import React from 'react';
import { Provider } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';
import { createBrowserHistory } from 'history';
import { ApiProvider, reducer } from '@arborian/narrf';
import Router from './components/Router';

const store = configureStore({ reducer });
const history = createBrowserHistory();

export default function App() {
    const onError = (err) => {
        console.error(err);
    };

    return (
        <Provider store={store}>
            <ApiProvider
                ui_home="/"
                onError={onError}
                history={history}
                api_root={process.env.REACT_APP_API_ROOT}
                client_id={process.env.REACT_APP_APP_KEY}
            >
                <Router />
            </ApiProvider>
        </Provider>
    );
}
