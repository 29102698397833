export function generateConfigurationDefaultValues() {
    return {
        name: '',
        florence: {
            base_url: null,
            user_name: null,
            private_key: null,
            root_container_path: null,
            send_notifications: false,
            create_global_binders: false,
            aws_access_key: null,
            aws_secret_key: null,
            sqs_queue_url: null,
            study_profile: {
                condition_or_disease_area: null,
                cro_name: null,
                device_or_drug: null,
                facility: {
                    country_code: null,
                    organization_name: null,
                },
                sponsor: {
                    external_system_sponsor_id: null,
                    sponsor_name: null,
                },
                study_site: {
                    external_system_site_id: null,
                    lead_coordinator_name: null,
                    principal_investigator_name: null,
                    site_id: null,
                    site_name: null,
                },
            },
        },
        oncore: {
            dburi: null,
            schema_name: null,
            polling_interval_s: null,
            base_url: null,
            client_id: null,
            client_secret: null,
            session_config: {},
        },
        team_config: {
            createdAt: null,
            name: null,
            hasGlobalBinders: false,
            studies: [],
        },
        annotation_name: '',
        onprem: false,
        database_query_interval: 60,
        binder_creation_window: 180,
        binder_creation_method: '',
        use_short_title: false,
        role_mapping: [],
        protocol_type_mapping: [],
        datatable4_mapping: [],
    };
}
