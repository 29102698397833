import React, {useState} from 'react';
import { useApi } from '@arborian/narrf';
import BasePage from './BasePage';
import {Formik} from 'formik'
import {BackfillForm} from "../components/backfill/BackfillForm";
import * as PropTypes from "prop-types";
import {Backdrop, makeStyles, Modal} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
}));


Formik.propTypes = {children: PropTypes.node};


export default function BackfillPage() {

    const classes = useStyles();

    const api = useApi();
    const [openModal, setOpenModal] = useState(false);

    const validate = (values) => {
        const errors = {};

        if (!values.protocol_nos) {
            errors.protocol_nos = 'Required';
        }
        if (!values.team_id){
            errors.team_id = 'Required';
        }

        return errors;
    };

    return (
        <BasePage subtitle="Configuration List">
            <h2>Backfill</h2>
            <Formik initialValues={{
                        'team_id': '',
                        'protocol_nos': '',
                        'notify': true,
                    }}

                    validate={validate}

                    onSubmit={ async (values, {resetForm}) => {
                        const url = api.directory.data.links['backfill.BackfillUpload'];
                        try {
                            const backfillResponse = await api.fetchJson(url, {
                                method: 'POST',
                                json: {
                                    ...values
                                },
                            });
                            console.log(backfillResponse)
                            resetForm();
                            setOpenModal(true);

                        } catch (err) {
                            console.log(err)
                        }
                    }}

            >
                <BackfillForm />
            </Formik>
            <Modal open={openModal}
                   onClose={() => setOpenModal(false)}
                   className={classes.modal}
                   closeAfterTransition
                   BackdropComponent={Backdrop}
                   BackdropProps={{
                       timeout: 500,
                   }}
            >
                <div className={classes.paper}>
                    <h2>Success!</h2>
                    <p>Your API fetch was successful.</p>
                </div>
            </Modal>
        </BasePage>
    );
}

